import React from "react";

export default function NavBar() {
  return (
    <>
      <nav className="navigation-menu navigation-menu--side-panel">
        <div className="side-panel--inner-wrapper">
          <div className="inner-wrapper--top-side">
            <div className="top-side--social-wrapper">
              <ul className="social-wrapper--social-list">
                <li className="social-list--item">
                  <a href="index.html">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="social-list--item">
                  <a href="index.html">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="social-list--item">
                  <a href="index.html">
                    <i className="fab fa-google"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="inner-wrapper--navigation-toggle">
            <div className="navigation-toggle--toggle-wrapper">
              <div className="toggle-wrapper--toggle">
                <div className="toggle--menu-icon">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="inner-wrapper--bottom-side">
            <div className="bottom-side--search-form">
              <div className="search-form--toggle">
                <div className="toggle--icon-wrapper">
                  <div className="icon-wrapper--show-icon icon-wrapper--icon">
                    <i className="fa fa-search"></i>
                  </div>
                  <div className="icon-wrapper--hide-icon icon-wrapper--icon">
                    <i className="fa fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="side-panel--navigation-menu-panel">
          <div className="navigation-menu-panel--menu-wrapper">
            <ul className="menu-wrapper--menu-list active-window">
              <li className="menu-item menu-item-has-children">
                <a href="#">Homepages</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="index.html">Home Classic</a>
                  </li>
                  <li className="menu-item">
                    <a href="home-digital.html">Home Digital</a>
                  </li>
                  <li className="menu-item">
                    <a href="home-creative.html">Home Creative</a>
                  </li>
                  <li className="menu-item">
                    <a href="home-presentation.html">Home Presentation</a>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Home Single</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="single-glitch-links.html">Glitch Links</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-slideshow.html">SlideshowMin</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-hover-grid.html">Hover Grid</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-html-video.html">HTML Video</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-vimeo-video.html">Vimeo Video</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-youtube-video.html">Youtube Video</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <a href="#">Portfolio</a>
                <ul className="sub-menu">
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Porfolio Scale</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="porfolio-scale-two-columns.html">
                          Porfolio Scale 2 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-scale-three-columns.html">
                          Porfolio Scale 3 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-scale-four-columns.html">
                          Porfolio Scale 4 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-scale-metro.html">
                          Porfolio Scale Metro
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Porfolio Fade</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="porfolio-fade-two-columns.html">
                          Porfolio Fade 2 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-fade-three-columns.html">
                          Porfolio Fade 3 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-fade-four-columns.html">
                          Porfolio Fade 4 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-fade-metro.html">
                          Porfolio Fade Metro
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Porfolio Slide</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="porfolio-slide-two-columns.html">
                          Porfolio Slide 2 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-slide-three-columns.html">
                          Porfolio Slide 3 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-slide-four-columns.html">
                          Porfolio Slide 4 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-slide-metro.html">
                          Porfolio Slide Metro
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Single Project</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="single-project-1.html">Single Project 1</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-project-2.html">Single Project 2</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-project-3.html">Single Project 3</a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Gallery</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="gallery-two-columns.html">Gallery 2 Columns</a>
                      </li>
                      <li className="menu-item">
                        <a href="gallery-three-columns.html">
                          Gallery 3 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="gallery-four-columns.html">
                          Gallery 4 Columns
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <a href="#">Pages</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="about-us.html">About Us</a>
                  </li>
                  <li className="menu-item">
                    <a href="services.html">Services</a>
                  </li>
                  <li className="menu-item">
                    <a href="team.html">Team</a>
                  </li>
                  <li className="menu-item">
                    <a href="contact.html">Contact</a>
                  </li>
                  <li className="menu-item">
                    <a href="pricing.html">Pricing</a>
                  </li>
                  <li className="menu-item">
                    <a href="clients.html">Clients</a>
                  </li>
                  <li className="menu-item">
                    <a href="faq.html">FAQ</a>
                  </li>
                  <li className="menu-item">
                    <a href="error-page.html">Error Page</a>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Coming Soon</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="coming-soon-creative.html">
                          Coming Soon Creative
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="coming-soon-simple.html">Coming Soon Simple</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <a href="#">Blog</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="blog-list.html">Blog List</a>
                  </li>
                  <li className="menu-item">
                    <a href="blog-blocks.html">Blog Blocks</a>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Grid</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-grid-full-width.html">Full Width</a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-grid-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Boxed</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-boxed-full-width.html">Full Width</a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-boxed-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Images</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-images-full-width.html">Full Width</a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-images-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Only Text</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-only-text-full-width.html">Full Width</a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-only-text-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Post Layout 1</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-post-layout-1-full-width.html">
                          Full Width
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-post-layout-1-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Post Layout 2</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-post-layout-2-full-width.html">
                          Full Width
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-post-layout-2-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <a href="#">Shortcodes</a>
                <ul className="sub-menu">
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Interactive Elements</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="elements-testimonials-slider.html">
                          <i className="fas fa-sliders-h"></i>Testimonials
                          Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-background-slider.html">
                          <i className="fas fa-images"></i>Background Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-video-toggles.html">
                          <i className="fas fa-video"></i>Video Toggles
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-contact-form.html">
                          <i className="fas fa-envelope"></i>Contact Form
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-recent-posts.html">
                          <i className="fas fa-comment-alt"></i>Recent Posts
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-hover-boxes.html">
                          <i className="fas fa-image"></i>Hover Boxes
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-google-maps.html">
                          <i className="fas fa-map"></i>Google Map
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-services.html">
                          <i className="fas fa-users"></i>Services
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">General Elements</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="elements-accordion-gallery.html">
                          <i className="fas fa-images"></i>Accordion Gallery
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-call-to-action.html">
                          <i className="fas fa-exclamation-triangle"></i>Call To
                          Action
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-clients-slider.html">
                          <i className="fas fa-sliders-h"></i>Clients Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-team-slider.html">
                          <i className="fas fa-sliders-h"></i>Team Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-accordions.html">
                          <i className="fas fa-list-alt"></i>Accordions
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-subscribe.html">
                          <i className="fas fa-at"></i>Subscribe
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-buttons.html">
                          <i className="fas fa-toggle-on"></i>Buttons
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-clients.html">
                          <i className="fas fa-address-card"></i>Clients
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-team.html">
                          <i className="fas fa-user-plus"></i>Team
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-tabs.html">
                          <i className="fas fa-columns"></i>Tabs
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Text & Containers</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="elements-pricing-tables.html">
                          <i className="fas fa-money-bill-alt"></i>Pricing
                          Tables
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-info-banners.html">
                          <i className="fas fa-info-circle"></i>Info Banners
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-social-icons.html">
                          <i className="fab fa-facebook"></i>Social Icons
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-blockquote.html">
                          <i className="fas fa-quote-right"></i>Blockquote
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-lists.html">
                          <i className="fas fa-list-ul"></i>Lists
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-headings.html">
                          <i className="fas fa-heading"></i>Headings
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-columns.html">
                          <i className="fas fa-columns"></i>Columns
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Infographics</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="elements-services-slider.html">
                          <i className="fas fa-sliders-h"></i>Services Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-progress-bars.html">
                          <i className="fas fa-stopwatch"></i>Progress bars
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-steps-slider.html">
                          <i className="fas fa-sliders-h"></i>Steps Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-countdown.html">
                          <i className="fas fa-hourglass"></i>Countdown
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-counters.html">
                          <i className="fas fa-clock"></i>Counters
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-process-steps.html">
                          <i className="fas fa-sort-numeric-down"></i>Steps
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="side-panel--shop-panel">
          <div className="shop-panel--menu-wrapper"></div>
        </div>
        <div className="side-panel--search-panel">
          <div className="search-panel--search-wrapper">
            <form className="search-wrapper--form">
              <input type="text" placeholder="Input some keywords..." />
              <button type="submit">
                <i className="fa fa-search"></i>
              </button>
            </form>
          </div>
        </div>
      </nav>
      <nav className="navigation-menu navigation-menu--mobile-menu">
        <div className="mobile-menu--toggle-wrapper">
          <div className="toggle-wrapper--toggle">
            <div className="toggle--menu-icon">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        </div>
        <div className="mobile-menu--inner-wrapper">
          <div className="inner-wrapper--menu-wrapper">
            <ul className="menu-wrapper--menu-list active-window">
              <li className="menu-item menu-item-has-children">
                <a href="#">Homepages</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="index.html">Home Classic</a>
                  </li>
                  <li className="menu-item">
                    <a href="home-digital.html">Home Digital</a>
                  </li>
                  <li className="menu-item">
                    <a href="home-creative.html">Home Creative</a>
                  </li>
                  <li className="menu-item">
                    <a href="home-presentation.html">Home Presentation</a>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Home Single</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="single-glitch-links.html">Glitch Links</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-slideshow.html">SlideshowMin</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-hover-grid.html">Hover Grid</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-html-video.html">HTML Video</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-vimeo-video.html">Vimeo Video</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-youtube-video.html">Youtube Video</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <a href="#">Portfolio</a>
                <ul className="sub-menu">
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Porfolio Scale</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="porfolio-scale-two-columns.html">
                          Porfolio Scale 2 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-scale-three-columns.html">
                          Porfolio Scale 3 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-scale-four-columns.html">
                          Porfolio Scale 4 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-scale-metro.html">
                          Porfolio Scale Metro
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Porfolio Fade</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="porfolio-fade-two-columns.html">
                          Porfolio Fade 2 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-fade-three-columns.html">
                          Porfolio Fade 3 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-fade-four-columns.html">
                          Porfolio Fade 4 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-fade-metro.html">
                          Porfolio Fade Metro
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Porfolio Slide</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="porfolio-slide-two-columns.html">
                          Porfolio Slide 2 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-slide-three-columns.html">
                          Porfolio Slide 3 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-slide-four-columns.html">
                          Porfolio Slide 4 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="porfolio-slide-metro.html">
                          Porfolio Slide Metro
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Single Project</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="single-project-1.html">Single Project 1</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-project-2.html">Single Project 2</a>
                      </li>
                      <li className="menu-item">
                        <a href="single-project-3.html">Single Project 3</a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Gallery</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="gallery-two-columns.html">Gallery 2 Columns</a>
                      </li>
                      <li className="menu-item">
                        <a href="gallery-three-columns.html">
                          Gallery 3 Columns
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="gallery-four-columns.html">
                          Gallery 4 Columns
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <a href="#">Pages</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="error-page.html">Error Page</a>
                  </li>
                  <li className="menu-item">
                    <a href="about-us.html">About Us</a>
                  </li>
                  <li className="menu-item">
                    <a href="services.html">Services</a>
                  </li>
                  <li className="menu-item">
                    <a href="contact.html">Contact</a>
                  </li>
                  <li className="menu-item">
                    <a href="pricing.html">Pricing</a>
                  </li>
                  <li className="menu-item">
                    <a href="clients.html">Clients</a>
                  </li>
                  <li className="menu-item">
                    <a href="team.html">Team</a>
                  </li>
                  <li className="menu-item">
                    <a href="faq.html">FAQ</a>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Coming Soon</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="coming-soon-creative.html">
                          Coming Soon Creative
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="coming-soon-simple.html">Coming Soon Simple</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <a href="#">Blog</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="blog-list.html">Blog List</a>
                  </li>
                  <li className="menu-item">
                    <a href="blog-blocks.html">Blog Blocks</a>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Grid</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-grid-full-width.html">Full Width</a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-grid-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Boxed</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-boxed-full-width.html">Full Width</a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-boxed-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Images</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-images-full-width.html">Full Width</a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-images-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Only Text</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-only-text-full-width.html">Full Width</a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-only-text-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Post Layout 1</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-post-layout-1-full-width.html">
                          Full Width
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-post-layout-1-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Blog Post Layout 2</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="blog-post-layout-2-full-width.html">
                          Full Width
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="blog-post-layout-2-sidebar-layout.html">
                          Sidebar Layout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <a href="#">Shortcodes</a>
                <ul className="sub-menu">
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Interactive Elements</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="elements-testimonials-slider.html">
                          <i className="fas fa-sliders-h"></i>Testimonials
                          Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-background-slider.html">
                          <i className="fas fa-images"></i>Background Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-video-toggles.html">
                          <i className="fas fa-video"></i>Video Toggles
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-contact-form.html">
                          <i className="fas fa-envelope"></i>Contact Form
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-recent-posts.html">
                          <i className="fas fa-comment-alt"></i>Recent Posts
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-hover-boxes.html">
                          <i className="fas fa-image"></i>Hover Boxes
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-google-maps.html">
                          <i className="fas fa-map"></i>Google Map
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-services.html">
                          <i className="fas fa-users"></i>Services
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">General Elements</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="elements-accordion-gallery.html">
                          <i className="fas fa-images"></i>Accordion Gallery
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-call-to-action.html">
                          <i className="fas fa-exclamation-triangle"></i>Call To
                          Action
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-clients-slider.html">
                          <i className="fas fa-sliders-h"></i>Clients Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-team-slider.html">
                          <i className="fas fa-sliders-h"></i>Team Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-accordions.html">
                          <i className="fas fa-list-alt"></i>Accordions
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-subscribe.html">
                          <i className="fas fa-at"></i>Subscribe
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-buttons.html">
                          <i className="fas fa-toggle-on"></i>Buttons
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-clients.html">
                          <i className="fas fa-address-card"></i>Clients
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-team.html">
                          <i className="fas fa-user-plus"></i>Team
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-tabs.html">
                          <i className="fas fa-columns"></i>Tabs
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Text & Containers</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="elements-pricing-tables.html">
                          <i className="fas fa-money-bill-alt"></i>Pricing
                          Tables
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-info-banners.html">
                          <i className="fas fa-info-circle"></i>Info Banners
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-social-icons.html">
                          <i className="fab fa-facebook"></i>Social Icons
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-blockquote.html">
                          <i className="fas fa-quote-right"></i>Blockquote
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-lists.html">
                          <i className="fas fa-list-ul"></i>Lists
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-headings.html">
                          <i className="fas fa-heading"></i>Headings
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-columns.html">
                          <i className="fas fa-columns"></i>Columns
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="index.html">Infographics</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="elements-services-slider.html">
                          <i className="fas fa-sliders-h"></i>Services Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-progress-bars.html">
                          <i className="fas fa-stopwatch"></i>Progress bars
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-steps-slider.html">
                          <i className="fas fa-sliders-h"></i>Steps Slider
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-countdown.html">
                          <i className="fas fa-hourglass"></i>Countdown
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-counters.html">
                          <i className="fas fa-clock"></i>Counters
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="elements-process-steps.html">
                          <i className="fas fa-sort-numeric-down"></i>Steps
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
