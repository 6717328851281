import React, { useEffect } from "react";

export default function Services() {
  return (
    <section className="small-section progress-bars border-bottom-simple gray-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h2 className="mb-15">We are working on web design and branding</h2>
            <div className="social-icons-type-1 justify-content-start">
              <a href="index.html" className="social-box facebook-h">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="index.html" className="social-box linkedin-h">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="index.html" className="social-box twitter-h">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="index.html" className="social-box instagram-h">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="index.html" className="social-box google-plus-h">
                <i className="fab fa-google-plus-g"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-6 responsive-padding">
            <div className="progress-bar-type-1 margin">
              <div
                className="progress-bar-line1 progress-bar-line"
                data-progress="0.80"
              >
                <p className="skill-title">HTML5 - 5 YEARS EXPERIENCE</p>
              </div>
              <div
                className="progress-bar-line2 progress-bar-line"
                data-progress="0.90"
              >
                <p className="skill-title">WORDPRESS - 6 YEARS EXPERIENCE</p>
              </div>
              <div
                className="progress-bar-line3 progress-bar-line"
                data-progress="1"
              >
                <p className="skill-title">PHOTOGRAPHY - 7 YEARS EXPERIENCE</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
