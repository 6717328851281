import React from "react";

export default function Footer() {
  return (
    <footer className="footer-type-2">
      <div className="container">
        <div className="row footer-type-2--footer-body">
          <div className="col-md-8 col-xs-12">
            <div className="footer-body--credits">
              <div className="credits--credits-wrapper">
                @ 2019 Keito by <a href="index.html">ThemeHiegger</a>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-xs-12">
            <div
              className="footer-body--logo-wrapper"
              style={{ display: "block", textAlign: "right" }}
            >
              <a href="index.html">
                <img
                  src="http://via.placeholder.com/1920x1280"
                  alt=""
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
