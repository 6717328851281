import React from "react";

export default function Loader() {
  return (
    <div className="page-loader">
      <div className="preloader-wrapper">
        <div className="sk-cube-grid">
          <div className="sk-cube sk-cube-1"></div>
          <div className="sk-cube sk-cube-2"></div>
          <div className="sk-cube sk-cube-3"></div>
          <div className="sk-cube sk-cube-4"></div>
          <div className="sk-cube sk-cube-5"></div>
          <div className="sk-cube sk-cube-6"></div>
          <div className="sk-cube sk-cube-7"></div>
          <div className="sk-cube sk-cube-8"></div>
          <div className="sk-cube sk-cube-9"></div>
        </div>
      </div>
    </div>
  );
}
