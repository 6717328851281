import * as React from "react";
import Layout from "../components/home/layout";
import Hero from "../components/home/hero";
import About from "../components/home/about";
import Services from "../components/home/services";
import Footer from "../components/home/footer";

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <About />
      <Services />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
