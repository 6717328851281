import React from "react";
import useMainScript from "../../hooks/useMainScript";
import Loader from "../loader";
import NavBar from "./navbar";

export default function Layout({ children }) {
  useMainScript();

  return (
    <main className="page-wrapper">
      <Loader />
      <NavBar />
      <div className="content-page-wrapper content-page-wrapper--side-menu">
        {children}
      </div>
    </main>
  );
}
