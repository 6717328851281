import React from "react";

export default function About() {
  return (
    <section className="about-us large-section">
      <div className="container">
        <div className="row">
          <div className="col section-heading">
            <div className="section-heading--title">
              <h2 className="title--show-title">About us</h2>
              <h2 className="title--back-title">About us</h2>
              <p className="title--subtitle">
                Ex mei reformidans ancillae delicata, est amet dolor lobortis
                id, per no sadipscing persequeris sea ludus eirmod usu sint
                dicunt
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row about-us--images">
          <div className="col-lg-6">
            <div className="images--img-wrapper">
              <img
                src="http://via.placeholder.com/1920x1280"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="images--img-wrapper m-0">
              <img
                src="http://via.placeholder.com/1920x1280"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="row about-us--inner-wrapper pt-0">
          <div className="col-lg-4 inner-wrapper--column-wrapper">
            <p className="primary-color">Original ideas</p>
            <h5>Testing for Perfection</h5>
            <p>
              Lorem ipsum dolor sit amet, consectet adipisicing. Adipisci
              aliquid beat commodi consectetur corporis cumque, deleniti
              doloribus facere fugiat
            </p>
          </div>
          <div className="col-lg-4 inner-wrapper--column-wrapper">
            <p className="primary-color">Graphic designs</p>
            <h5>Handcrafted Templates</h5>
            <p>
              Lorem ipsum dolor sit amet, consectet adipisicing. Adipisci
              aliquid beat commodi consectetur corporis cumque, deleniti
              doloribus facere fugiat
            </p>
          </div>
          <div className="col-lg-4 inner-wrapper--column-wrapper">
            <p className="primary-color">SEO marketing</p>
            <h5>Discussion of the Idea</h5>
            <p>
              Lorem ipsum dolor sit amet, consectet adipisicing. Adipisci
              aliquid beat commodi consectetur corporis cumque, deleniti
              doloribus facere fugiat
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
