import React from "react";

export default function Hero() {
  return (
    <div>
      <section
        className="hero-header bg-position-center bg-size-cover"
        style={{
          backgroundImage: "url('http://via.placeholder.com/1920x1280')",
        }}
      >
        <div className="hero-header--hero-content">
          <div className="container">
            <div className="hero-content--hero-wrapper">
              <div className="hero-wrapper--content">
                <h1 className="hero-heading white-color mb-15">
                  A creative agency specialized in brand strategy and design
                </h1>
                <p className="hero-subtitle white-color">
                  Introducing the best practices from the world’s top design
                  leaders
                </p>
              </div>
              <div className="hero-content--video-wrapper">
                <div className="video-toggle-type-1">
                  <a
                    className="video-play-button video-popup"
                    href="#video-post-1"
                  >
                    <i className="fas fa-play"></i>
                  </a>
                  <div
                    id="video-post-1"
                    className="white-popup-block mfp-hide mfp-fade"
                  >
                    <div className="modal-video-box">
                      <iframe
                        src="https://www.youtube.com/embed/iGpuQ0ioPrM"
                        allowFullScreen
                        title="placeholder"
                      ></iframe>
                      <button
                        title="Close (Esc)"
                        type="button"
                        className="mfp-close"
                      >
                        ×
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
