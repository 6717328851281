import { useEffect } from "react";

export default function useMainScript() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/js/main.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
}
